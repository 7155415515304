import gql from 'graphql-tag'
import { apolloClient } from '@/libs/vue-apollo'

export default {
  namespaced: true,
  state: {
    organizations: {
      nodes: [],
      totalCount: 0,
    },
    isLoading: false,
  },

  // Mutations
  mutations: {
    ADD_ORGANIZATION(state, val) {
      state.organizations.nodes.unshift(val)
      state.organizations.totalCount += 1
    },
  },

  // Actions
  actions: {
    async createOrganization({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })
      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation createOrganization($input: CreateOrganizationInput!) {
              createOrganization(createOrganizationInput: $input) {
                id
                prefix
                organization
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        commit('ADD_ORGANIZATION', response.data.createOrganization)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
  },
}
