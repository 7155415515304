<template>
  <b-card v-if="data" class="card-transaction" no-body>
    <router-link
      :to="{
        path: `${
          route === 'dashboard-ecommerce' ? '/processes' : '/myProcesses'
        }`,
      }"
    >
      <b-card-header header-bg-variant="primary bg-darken-2" class="header">
        <b-card-title class="title">
          <feather-icon class="logo" icon="ToolIcon" />
          Processes
        </b-card-title>
      </b-card-header>
    </router-link>
    <b-card-body>
      <div class="transaction-item">
        <router-link
          :to="{
            path: `${
              route === 'dashboard-ecommerce' ? '/processes' : '/myProcesses'
            }`,
          }"
        >
          <b-media no-body>
            <b-media-body>
              <h6 class="transaction-header">
                <span class="text-props">
                  <span>{{ data.all }}</span>
                </span>
                Processes
              </h6>
            </b-media-body>
          </b-media>
        </router-link>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{
              name: `${
                route === 'dashboard-ecommerce'
                  ? 'process-list'
                  : 'my-process-list'
              }`,
              query: { q: 'has:scheduled' },
            }"
            link-class="text-props text-primary"
          >
            <b-media-aside>
              <feather-icon size="18" icon="RepeatIcon" class="text-primary" />
            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{
                  name: `${
                    route === 'dashboard-ecommerce'
                      ? 'process-list'
                      : 'my-process-list'
                  }`,
                  query: { q: 'has:scheduled' },
                }"
                link-class="text-props text-primary"
              >
                <span>{{ data.scheduled }}</span>
              </router-link>
              scheduled with dates
            </h6>
          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{
              name: `${
                route === 'dashboard-ecommerce'
                  ? 'process-list'
                  : 'my-process-list'
              }`,
              query: { q: 'has:scheduledNoDates' },
            }"
            link-class="text-props text-primary"
          >
            <b-media-aside>
              <feather-icon
                v-if="data.scheduledNoDates > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-warning"
              />
              <feather-icon
                v-else
                size="18"
                icon="CheckCircleIcon"
                class="text-success"
              />
            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{
                  name: `${
                    route === 'dashboard-ecommerce'
                      ? 'process-list'
                      : 'my-process-list'
                  }`,
                  query: { q: 'has:scheduledNoDates' },
                }"
                link-class="text-props text-primary"
              >
                <span>{{ data.scheduledNoDates }}</span>
              </router-link>
              scheduled, but missing dates
            </h6>
          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{
              name: `${
                route === 'dashboard-ecommerce'
                  ? 'process-list'
                  : 'my-process-list'
              }`,
              query: { q: 'has:needed' },
            }"
            link-class="text-props text-primary"
          >
            <b-media-aside>
              <feather-icon
                size="18"
                icon="MousePointerIcon"
                class="text-primary"
              />
            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{
                  name: `${
                    route === 'dashboard-ecommerce'
                      ? 'process-list'
                      : 'my-process-list'
                  }`,
                  query: { q: 'has:needed' },
                }"
                link-class="text-props text-primary"
              >
                <span>{{ data.needed }}</span>
              </router-link>
              as needed
            </h6>
          </b-media-body>
        </b-media>
      </div>

      <hr />

      <div v-if="route === 'dashboard-ecommerce'" class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{
              name: `${
                route === 'dashboard-ecommerce'
                  ? 'process-list'
                  : 'my-process-list'
              }`,
              query: { q: 'has:noRoles' },
            }"
            link-class="text-props text-primary"
          >
            <b-media-aside>
              <feather-icon
                v-if="data.noRoles > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-warning"
              />
              <feather-icon
                v-else
                size="18"
                icon="CheckCircleIcon"
                class="text-success"
              />
            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{
                  name: `${
                    route === 'dashboard-ecommerce'
                      ? 'process-list'
                      : 'my-process-list'
                  }`,
                  query: { q: 'has:noRoles' },
                }"
                link-class="text-props text-primary"
              >
                <span>{{ data.noRoles }}</span>
              </router-link>
              with no assigned role
            </h6>
          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{
              name: `${
                route === 'dashboard-ecommerce'
                  ? 'process-list'
                  : 'my-process-list'
              }`,
              query: { q: 'has:noDocs' },
            }"
            link-class="text-props text-primary"
          >
            <b-media-aside>
              <feather-icon
                v-if="data.noDescription > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-warning"
              />
              <feather-icon
                v-else
                size="18"
                icon="CheckCircleIcon"
                class="text-success"
              />
            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{
                  name: `${
                    route === 'dashboard-ecommerce'
                      ? 'process-list'
                      : 'my-process-list'
                  }`,
                  query: { q: 'has:noDocs' },
                }"
                link-class="text-props text-primary"
              >
                <span>{{ data.noDocs }}</span>
              </router-link>
              with no document link
            </h6>
          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{
              name: `${
                route === 'dashboard-ecommerce'
                  ? 'process-list'
                  : 'my-process-list'
              }`,
              query: { q: 'has:noDescription' },
            }"
            link-class="text-props text-primary"
          >
            <b-media-aside>
              <feather-icon
                v-if="data.noDescription > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-warning"
              />
              <feather-icon
                v-else
                size="18"
                icon="CheckCircleIcon"
                class="text-success"
              />
            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{
                  name: `${
                    route === 'dashboard-ecommerce'
                      ? 'process-list'
                      : 'my-process-list'
                  }`,
                  query: { q: 'has:noDescription' },
                }"
                link-class="text-props text-primary"
              >
                <span>{{ data.noDescription }}</span>
              </router-link>
              with no description
            </h6>
          </b-media-body>
        </b-media>
      </div>

      <div class="transaction-item">
        <b-media no-body>
          <router-link
            :to="{
              name: `${
                route === 'dashboard-ecommerce'
                  ? 'process-list'
                  : 'my-process-list'
              }`,
              query: { q: 'has:noLabel' },
            }"
            link-class="text-props text-primary"
          >
            <b-media-aside>
              <feather-icon
                v-if="data.noLabel > 0"
                size="18"
                icon="AlertTriangleIcon"
                class="text-warning"
              />
              <feather-icon
                v-else
                size="18"
                icon="CheckCircleIcon"
                class="text-success"
              />
            </b-media-aside>
          </router-link>
          <b-media-body>
            <h6 class="transaction-title">
              <router-link
                :to="{
                  name: `${
                    route === 'dashboard-ecommerce'
                      ? 'process-list'
                      : 'my-process-list'
                  }`,
                  query: { q: 'has:noLabel' },
                }"
                link-class="text-props text-primary"
              >
                <span>{{ data.noLabel }}</span>
              </router-link>
              with no labels
            </h6>
          </b-media-body>
        </b-media>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
  import { $themeConfig } from '@themeConfig'
  import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
  } from 'bootstrap-vue'

  export default {
    components: {
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BMediaBody,
      BMedia,
      BMediaAside,
      // BLink,
      // BImg,
    },
    setup() {
      const { wrench } = $themeConfig.icons
      return {
        wrench,
      }
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        route: this.$route.name,
      }
    },
  }
</script>

<style scoped>
  .media-style {
    background: none;
  }

  h6,
  span {
    font-weight: bold;
  }
  .header {
    margin-bottom: 10px;
  }
  .title {
    color: white;
  }
  .logo {
    height: 22px;
    width: 22px;
    filter: brightness(0) invert(1);
  }
  .transaction-title {
    color: black;
    font-weight: normal;
  }

  .transaction-header {
    color: black;
  }

  .text-props {
    color: #193a9a;
  }
</style>
