var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('b-card',{staticClass:"card-transaction",attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
      path: ("" + (_vm.route === 'dashboard-ecommerce' ? '/processes' : '/myProcesses')),
    }}},[_c('b-card-header',{staticClass:"header",attrs:{"header-bg-variant":"primary bg-darken-2"}},[_c('b-card-title',{staticClass:"title"},[_c('feather-icon',{staticClass:"logo",attrs:{"icon":"ToolIcon"}}),_vm._v(" Processes ")],1)],1)],1),_c('b-card-body',[_c('div',{staticClass:"transaction-item"},[_c('router-link',{attrs:{"to":{
          path: ("" + (_vm.route === 'dashboard-ecommerce' ? '/processes' : '/myProcesses')),
        }}},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-body',[_c('h6',{staticClass:"transaction-header"},[_c('span',{staticClass:"text-props"},[_c('span',[_vm._v(_vm._s(_vm.data.all))])]),_vm._v(" Processes ")])])],1)],1)],1),_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce'
                ? 'process-list'
                : 'my-process-list')),
            query: { q: 'has:scheduled' },
          },"link-class":"text-props text-primary"}},[_c('b-media-aside',[_c('feather-icon',{staticClass:"text-primary",attrs:{"size":"18","icon":"RepeatIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'process-list'
                    : 'my-process-list')),
                query: { q: 'has:scheduled' },
              },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.scheduled))])]),_vm._v(" scheduled with dates ")],1)])],1)],1),_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce'
                ? 'process-list'
                : 'my-process-list')),
            query: { q: 'has:scheduledNoDates' },
          },"link-class":"text-props text-primary"}},[_c('b-media-aside',[(_vm.data.scheduledNoDates > 0)?_c('feather-icon',{staticClass:"text-warning",attrs:{"size":"18","icon":"AlertTriangleIcon"}}):_c('feather-icon',{staticClass:"text-success",attrs:{"size":"18","icon":"CheckCircleIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'process-list'
                    : 'my-process-list')),
                query: { q: 'has:scheduledNoDates' },
              },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.scheduledNoDates))])]),_vm._v(" scheduled, but missing dates ")],1)])],1)],1),_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce'
                ? 'process-list'
                : 'my-process-list')),
            query: { q: 'has:needed' },
          },"link-class":"text-props text-primary"}},[_c('b-media-aside',[_c('feather-icon',{staticClass:"text-primary",attrs:{"size":"18","icon":"MousePointerIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'process-list'
                    : 'my-process-list')),
                query: { q: 'has:needed' },
              },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.needed))])]),_vm._v(" as needed ")],1)])],1)],1),_c('hr'),(_vm.route === 'dashboard-ecommerce')?_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce'
                ? 'process-list'
                : 'my-process-list')),
            query: { q: 'has:noRoles' },
          },"link-class":"text-props text-primary"}},[_c('b-media-aside',[(_vm.data.noRoles > 0)?_c('feather-icon',{staticClass:"text-warning",attrs:{"size":"18","icon":"AlertTriangleIcon"}}):_c('feather-icon',{staticClass:"text-success",attrs:{"size":"18","icon":"CheckCircleIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'process-list'
                    : 'my-process-list')),
                query: { q: 'has:noRoles' },
              },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.noRoles))])]),_vm._v(" with no assigned role ")],1)])],1)],1):_vm._e(),_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce'
                ? 'process-list'
                : 'my-process-list')),
            query: { q: 'has:noDocs' },
          },"link-class":"text-props text-primary"}},[_c('b-media-aside',[(_vm.data.noDescription > 0)?_c('feather-icon',{staticClass:"text-warning",attrs:{"size":"18","icon":"AlertTriangleIcon"}}):_c('feather-icon',{staticClass:"text-success",attrs:{"size":"18","icon":"CheckCircleIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'process-list'
                    : 'my-process-list')),
                query: { q: 'has:noDocs' },
              },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.noDocs))])]),_vm._v(" with no document link ")],1)])],1)],1),_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce'
                ? 'process-list'
                : 'my-process-list')),
            query: { q: 'has:noDescription' },
          },"link-class":"text-props text-primary"}},[_c('b-media-aside',[(_vm.data.noDescription > 0)?_c('feather-icon',{staticClass:"text-warning",attrs:{"size":"18","icon":"AlertTriangleIcon"}}):_c('feather-icon',{staticClass:"text-success",attrs:{"size":"18","icon":"CheckCircleIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'process-list'
                    : 'my-process-list')),
                query: { q: 'has:noDescription' },
              },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.noDescription))])]),_vm._v(" with no description ")],1)])],1)],1),_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce'
                ? 'process-list'
                : 'my-process-list')),
            query: { q: 'has:noLabel' },
          },"link-class":"text-props text-primary"}},[_c('b-media-aside',[(_vm.data.noLabel > 0)?_c('feather-icon',{staticClass:"text-warning",attrs:{"size":"18","icon":"AlertTriangleIcon"}}):_c('feather-icon',{staticClass:"text-success",attrs:{"size":"18","icon":"CheckCircleIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'process-list'
                    : 'my-process-list')),
                query: { q: 'has:noLabel' },
              },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.noLabel))])]),_vm._v(" with no labels ")],1)])],1)],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }