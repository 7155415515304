var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('b-card',{staticClass:"card-transaction",attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
      path: ("" + (_vm.route === 'dashboard-ecommerce' ? '/tasks' : '/myTasks')),
    }}},[_c('b-card-header',{staticClass:"header",attrs:{"header-bg-variant":"success"}},[_c('b-card-title',{staticClass:"title"},[_c('b-link',[_c('feather-icon',{staticClass:"text-white",attrs:{"size":"22","icon":"CheckSquareIcon"}})],1),_vm._v(" Tasks ")],1)],1)],1),_c('b-card-body',[_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            path: ("" + (_vm.route === 'dashboard-ecommerce' ? '/tasks' : '/myTasks')),
            query: { q: 'is:late', d: 'org' },
          }}},[_c('b-media-body',[_c('h6',{staticClass:"transaction-header"},[_c('router-link',{attrs:{"to":{
                  name: ("" + (_vm.route === 'dashboard-ecommerce'
                      ? 'task-list'
                      : 'my-task-list')),
                  query: { q: 'is:late', d: 'org' },
                },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.allLate))])]),(_vm.data.allLate == 1 || 0)?_c('span',[_vm._v(" Task is late ")]):_c('span',[_vm._v(" Tasks are late ")])],1)])],1)],1)],1),_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce' ? 'task-list' : 'my-task-list')),
            query: { q: 'is:late&inProgress', d: 'org' },
          },"link-class":"text-props text-primary"}},[_c('b-media-aside',[(_vm.data.inProgress > 0)?_c('feather-icon',{staticClass:"text-warning",attrs:{"size":"18","icon":"AlertTriangleIcon"}}):_c('feather-icon',{staticClass:"text-success",attrs:{"size":"18","icon":"LoaderIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'task-list'
                    : 'my-task-list')),
                query: { q: 'is:late&inProgress', d: 'org' },
              },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.inProgress))])]),_vm._v(" are late and in Progress ")],1)])],1)],1),_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce' ? 'task-list' : 'my-task-list')),
            query: { q: 'is:late&notStarted', d: 'org' },
          },"link-class":"text-props text-primary"}},[_c('b-media-aside',[(_vm.data.notStarted > 0)?_c('feather-icon',{staticClass:"text-danger",attrs:{"size":"18","icon":"AlertTriangleIcon"}}):_c('feather-icon',{staticClass:"text-success",attrs:{"size":"18","icon":"CheckCircleIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'task-list'
                    : 'my-task-list')),
                query: { q: 'is:late&notStarted', d: 'org' },
              },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.notStarted))])]),_vm._v(" are late and Not Started ")],1)])],1)],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }