import ability from '@/libs/acl/ability'

const ACL_ROLES = {
  admin: [
    {
      action: 'manage',
      subject: 'all',
    },
  ],
  manager: [
    {
      action: 'manage',
      subject: 'all',
    },
  ],
  user: [
    {
      action: 'read',
      subject: 'myDashboard',
    },
    {
      action: 'read',
      subject: 'myRole',
    },
    {
      action: 'read',
      subject: 'myProfile',
    },
    {
      action: 'read',
      subject: 'myProcess',
    },
    {
      action: 'read',
      subject: 'myTask',
    },
  ],
}

export default {
  namespaced: true,
  state: {
    currentUser: null,
    role: null,
  },
  getters: {
    isLoggedIn: state => state.currentUser,
    acl: state => ACL_ROLES[state.role],
  },
  mutations: {
    SET_CURRENT_USER(state, val) {
      state.currentUser = val
    },
    SET_ROLE(state, val) {
      state.role = val
    },
  },
  actions: {
    setRole(context, val) {
      context.commit('SET_ROLE', val)
      ability.update(context.getters.acl)
    },
  },
}
