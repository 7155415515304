var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('b-card',{staticClass:"card-transaction",attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
      path: ("" + (_vm.route === 'dashboard-ecommerce' ? '/roles' : '/myRoles')),
    }}},[_c('b-card-header',{staticClass:"header",attrs:{"header-bg-variant":"info bg-darken-2"}},[_c('b-card-title',{staticClass:"title"},[_c('b-link',{staticClass:"text-props"},[_c('feather-icon',{staticClass:"text-white",attrs:{"size":"22","icon":"AwardIcon"}})],1),_vm._v(" Roles ")],1)],1)],1),_c('b-card-body',[_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            path: ("" + (_vm.route === 'dashboard-ecommerce' ? '/roles' : '/myRoles')),
          }}},[_c('b-media-body',[_c('h6',{staticClass:"transaction-header"},[_c('span',[_c('span',{staticClass:"text-props"},[_vm._v(_vm._s(_vm.data.all))])]),_vm._v(" Roles ")])])],1)],1)],1),(_vm.route === 'dashboard-ecommerce')?_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce' ? 'role-list' : 'my-role-list')),
            query: { q: 'no:user' },
          },"link-class":"text-props  text-primary"}},[_c('b-media-aside',[(_vm.data.noUsers > 0)?_c('feather-icon',{staticClass:"text-warning",attrs:{"size":"18","icon":"AlertTriangleIcon"}}):_c('feather-icon',{staticClass:"text-success",attrs:{"size":"18","icon":"CheckCircleIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'role-list'
                    : 'my-role-list')),
                query: { q: 'no:user' },
              },"link-class":"text-props  text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.noUsers))])]),_vm._v(" with no Person assigned ")],1)])],1)],1):_vm._e(),_c('div',{staticClass:"transaction-item"},[_c('b-media',{attrs:{"no-body":""}},[_c('router-link',{attrs:{"to":{
            name: ("" + (_vm.route === 'dashboard-ecommerce' ? 'role-list' : 'my-role-list')),
            query: { q: 'no:process' },
          },"link-class":"text-props text-primary"}},[_c('b-media-aside',[(_vm.data.noProcesses > 0)?_c('feather-icon',{staticClass:"text-warning",attrs:{"size":"18","icon":"AlertTriangleIcon"}}):_c('feather-icon',{staticClass:"text-success",attrs:{"size":"18","icon":"CheckCircleIcon"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_c('router-link',{attrs:{"to":{
                name: ("" + (_vm.route === 'dashboard-ecommerce'
                    ? 'role-list'
                    : 'my-role-list')),
                query: { q: 'no:process' },
              },"link-class":"text-props text-primary"}},[_c('span',[_vm._v(_vm._s(_vm.data.noProcesses))])]),_vm._v(" Roles with no Process assigned ")],1)])],1)],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }