import gql from 'graphql-tag'
import Vue from 'vue'
import { apolloClient } from '@/libs/vue-apollo'

export default {
  namespaced: true,
  state: {
    organizationSettings: null,
    isLoading: false,
  },
  getters: {
    // isLoggedIn: state => state.currentRole,
  },
  mutations: {
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_SETTINGS(state, val) {
      Vue.set(state, 'organizationSettings', val)
    },
  },
  actions: {
    async getSettings({ commit }) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`
            query settings {
              settings {
                organizationName
                timezone
                isEmailAuthEnabled
                isGoogleAuthEnabled
                isFacebookAuthEnabled
                sendDailyRemindersForLateTasksViaSMS
                sendWeeklyRemindersViaEmail
              }
            }
          `,
        })
        commit('SET_SETTINGS', response.data.settings)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchSettingsIfNotLoaded({ dispatch, state }) {
      if (!state.organizationSettings) {
        dispatch('getSettings')
      }
    },
    async updateSettings({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation updateSettings($input: UpdateSettingsInput!) {
              updateSettings(updateSettingsInput: $input) {
                organizationName
                timezone
                isEmailAuthEnabled
                isGoogleAuthEnabled
                isFacebookAuthEnabled
                sendDailyRemindersForLateTasksViaSMS
                sendWeeklyRemindersViaEmail
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        commit('SET_SETTINGS', response.data.updateSettings)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
  },
}
