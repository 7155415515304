export const isObjectEmpty = (obj) => Object.entries(obj).length === 0
export const isObjectNotEmpty = (obj) => Object.entries(obj).length !== 0
export const areArraysEqual = (arr1, arr2) => {
  // Check if lengths are equal
  if (arr1.length !== arr2.length) return false;

  // Sort both arrays and compare each item
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}