import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/theme/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import user from './user'
import role from './role'
import processModule from './process'
import task from './task'
import label from './label'
import processCategory from './process-category'
import setting from './setting'
import stats from './stats'
import filter from './filter'
import matrix from './matrix'
import organization from './organization'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    auth,
    user,
    role,
    process: processModule,
    task,
    label,
    processCategory,
    setting,
    stats,
    filter,
    matrix,
    organization,
  },
  strict: process.env.DEV,
})
