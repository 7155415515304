import Vue from 'vue'
import gql from 'graphql-tag'
import { apolloClient } from '@/libs/vue-apollo'

export default {
  namespaced: true,
  state: {
    processCategories: {
      nodes: [],
      totalCount: 0,
    },
    isLoading: false,
  },
  getters: {
    // isLoggedIn: state => state.currentLabel,
  },
  mutations: {
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_LABELS(state, val) {
      state.processCategories = val
    },
    ADD_LABEL(state, val) {
      state.processCategories.nodes.unshift(val)
      state.processCategories.totalCount += 1
    },
    UPDATE_LABEL(state, val) {
      const index = state.processCategories.nodes.findIndex(
        item => item.id === val.id
      )
      if (index !== -1) {
        Vue.set(state.processCategories.nodes, index, val)
      }
    },
    REMOVE_LABEL(state, id) {
      const index = state.processCategories.nodes.findIndex(
        item => item.id === id
      )
      if (index !== -1) {
        state.processCategories.nodes.splice(index, 1)
      }
    },
  },
  actions: {
    async getProcessCategories(
      { commit },
      { q, processCategoryType, offset, limit, sortBy, isDesc }
    ) {
      commit('SET_LOADING', true)

      try {
        const response = await apolloClient.query({
          query: gql`
            query processCategories(
              $q: String
              $offset: Int
              $limit: Int
              $sortBy: String
              $isDesc: Boolean
            ) {
              processCategories(
                q: $q
                offset: $offset
                limit: $limit
                sortBy: $sortBy
                isDesc: $isDesc
              ) {
                nodes {
                  id
                  prefix
                  description
                  increment
                }
                totalCount
              }
            }
          `,
          variables: {
            q,
            processCategoryType,
            offset,
            limit,
            sortBy,
            isDesc,
          },
        })
        commit('SET_LABELS', response.data.processCategories)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchProcessCategoriesIfNotLoaded({ dispatch, state }) {
      if (!state.processCategories.totalCount) {
        dispatch('getProcessCategories', {
          offset: 0,
          limit: 1000,
          sortBy: 'prefix',
          isDesc: false,
        })
      }
    },
    async addProcessCategory({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation createProcessCategory(
              $input: CreateProcessCategoryInput!
            ) {
              createProcessCategory(createProcessCategoryInput: $input) {
                id
                prefix
                description
                increment
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        commit('ADD_LABEL', response.data.createProcessCategory)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async updateProcessCategory({ commit }, payload) {
      Reflect.deleteProperty(payload, 'increment')
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation updateProcessCategory(
              $input: UpdateProcessCategoryInput!
            ) {
              updateProcessCategory(updateProcessCategoryInput: $input) {
                id
                prefix
                description
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        commit('UPDATE_LABEL', response.data.updateProcessCategory)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async removeProcessCategory({ commit }, id) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation removeProcessCategory($id: Int!) {
              removeProcessCategory(id: $id)
            }
          `,
          variables: {
            id,
          },
        })
        if (response.data.removeProcessCategory) {
          commit('REMOVE_LABEL', id)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
  },
}
