import Vue from 'vue'
import gql from 'graphql-tag'
import { apolloClient } from '@/libs/vue-apollo'

export default {
  namespaced: true,
  state: {
    tasks: {
      nodes: [],
      totalCount: 0,
    },
    currentUserTasks: {
      nodes: [],
      totalCount: 0,
    },
    isLoading: false,
    fieldValues: [],
    task: [],
  },
  getters: {
    // isLoggedIn: state => state.currentTask,
    fieldValues: state => state.fieldValues,
  },
  mutations: {
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_TASKS(state, val) {
      state.tasks = val
    },
    SET_TASK(state, task) {
      state.tasks = {
        ...state.tasks,
        nodes: state.tasks.nodes.map(t => t.handle === task.handle ? task : t)
      }
    },
    SET_CURRENT_USER_TASKS(state, val) {
      state.currentUserTasks = val
    },
    ADD_TASK(state, val) {
      if (!val.init_handle.includes(val.handle)) {
        let index = 0
        state.tasks.nodes.forEach((element, n) => {
          const search = element.init_handle === val.init_handle
          if (search) index = n
        })
        state.tasks.nodes.splice(0, index)
        state.tasks.totalCount -= 1
      }
      state.tasks.nodes.unshift(val)
      state.tasks.totalCount += 1
      state.task = val
      if (this.state.user.currentUser?.id === val.userId) {
        state.currentUserTasks.nodes.unshift(val)
        state.currentUserTasks.totalCount += 1
      }
    },
    UPDATE_TASK(state, val) {
      const index = state.tasks.nodes.findIndex(item =>
        val.init_handle.includes(item.init_handle)
      )
      if (index !== -1) {
        Vue.set(state.tasks.nodes, index, val)
      }
      if (this.state.user.currentUser?.id === val.userId) {
        const currentUserIndex = state.currentUserTasks.nodes.findIndex(
          item => item.handle === val.handle
        )
        if (currentUserIndex !== -1) {
          Vue.set(state.currentUserTasks.nodes, currentUserIndex, val)
        }
      }
    },
    UPDATE_TASKS(state, val) {
      for (let i = 0; i < val.nodes.length; i += 1) {
        const index = state.tasks.nodes.findIndex(
          item => item.handle === val.nodes[i].handle
        )
        if (index !== -1) {
          Vue.set(state.tasks.nodes, index, val.nodes[i])
        } else {
          state.tasks.nodes.push(val.nodes[i])
        }
        if (this.state.user.currentUser?.id === val.nodes[i].userId) {
          const currentUserIndex = state.currentUserTasks.nodes.findIndex(
            item => item.handle === val.nodes[i].handle
          )
          if (currentUserIndex !== -1) {
            Vue.set(
              state.currentUserTasks.nodes,
              currentUserIndex,
              val.nodes[i]
            )
          } else {
            state.currentUserTasks.nodes.push(val.nodes[i])
          }
        }
      }
    },
    REMOVE_TASK(state, id) {
      const index = state.tasks.nodes.findIndex(item => item.id === id)
      if (index !== -1) {
        state.tasks.nodes.splice(index, 1)
      }
    },
    SET_FIELD_VALUES(state, result) {
      state.fieldValues = result
    },
  },
  actions: {
    async getTasks(
      { commit },
      { q, offset, limit, sortBy, isDesc, from, to, paginated, getTasksToSkip, onlyMine }
    ) {
      commit('SET_LOADING', true)

      const name = onlyMine ? 'myTasks' : 'tasks'

      try {
        const response = await apolloClient.query({
          query: gql`
            query ${name}(
              $q: [String!]
              $offset: Int
              $limit: Int
              $sortBy: String
              $isDesc: Boolean
              $from: String
              $to: String
              $paginated: Boolean
              $getTasksToSkip: Boolean
            ) {
              ${name}(
                q: $q
                offset: $offset
                limit: $limit
                sortBy: $sortBy
                isDesc: $isDesc
                from: $from
                to: $to
                paginated: $paginated
                getTasksToSkip: $getTasksToSkip
              ) {
                totalCount
                tasksToSkip {
                  init_handle
                  id
                  status
                  deleted
                }
                nodes {
                  id
                  handle
                  init_handle
                  name
                  description
                  processId
                  userId
                  dueDate
                  status
                  is_late
                  deleted
                  updated_at
                  assignee {
                    name
                    id
                  }
                  process {
                    name
                    processHandle
                  }
                }
              }
            }
          `,
          variables: {
            q,
            offset,
            limit,
            sortBy,
            isDesc,
            from,
            to,
            paginated,
            getTasksToSkip,
          },
        })
        commit('SET_TASKS', response.data.tasks || response.data.myTasks)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchTasksIfNotLoaded({ dispatch, state }) {
      if (!state.allRoles.totalCount) {
        dispatch('getRoles', {
          offset: 0,
          limit: 1000,
        })
      }
    },
    async getCurrentUserTasks(
      { commit, state },
      { q, offset, limit, sortBy, isDesc, from, to }
    ) {
      commit('SET_LOADING', true)

      try {
        const response = await apolloClient.query({
          query: gql`
            query currentUserTasks(
              $q: [String!]
              $offset: Int
              $limit: Int
              $sortBy: String
              $isDesc: Boolean
              $from: String
              $to: String
            ) {
              currentUserTasks(
                q: $q
                offset: $offset
                limit: $limit
                sortBy: $sortBy
                isDesc: $isDesc
                from: $from
                to: $to
              ) {
                totalCount
                nodes {
                  id
                  handle
                  init_handle
                  name
                  description
                  processId
                  userId
                  dueDate
                  status
                  is_late
                  deleted
                  updated_at
                  process {
                    id
                    processHandle
                  }
                  assignee {
                    name
                  }
                }
              }
            }
          `,
          variables: {
            q,
            offset,
            limit,
            sortBy,
            isDesc,
            from,
            to,
          },
        })
        commit('SET_CURRENT_USER_TASKS', response.data.currentUserTasks)
        return state.currentUserTasks
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addTask({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })
      return apolloClient.mutate({
        mutation: gql`
          mutation createTask($input: CreateTaskInput!) {
            createTask(createTaskInput: $input) {
              id
              handle
              init_handle
              name
              description
              processId
              userId
              dueDate
              status
              is_late
              deleted
              updated_at
              process {
                id
                processHandle
              }
              assignee {
                name
              }
            }
          }
        `,
        variables: {
          input: payload,
        },
      }).then(res => {
        commit('ADD_TASK', res.data.createTask)
        commit('app/TOGGLE_LOADING', false, { root: true })
        return res
      })
    },
    async updateTask({ commit }, payload) {
      return apolloClient.mutate({
        mutation: gql`
          mutation updateTask($input: UpdateTaskInput!) {
            updateTask(updateTaskInput: $input) {
              id
              handle
              init_handle
              name
              description
              processId
              userId
              dueDate
              status
              is_late
              deleted
              updated_at
              process {
                id
                processHandle
              }
              assignee {
                name
              }
            }
          }
        `,
        variables: {
          input: payload,
        },
      })
    },
    async removeTask({ commit }, id) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation removeTask($id: Int!) {
              removeTask(id: $id)
            }
          `,
          variables: {
            id,
          },
        })
        if (response.data.removeTask) {
          commit('REMOVE_TASK', id)
        }
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async updateFutureTask(_, payload) {
      return apolloClient.mutate({
        mutation: gql`
          mutation updateFutureTask($input: UpdateFutureTaskInput!) {
            updateFutureTask(updateTaskInput: $input) {
              id
              handle
              init_handle
              name
              description
              processId
              userId
              dueDate
              status
              is_late
              deleted
              updated_at
              process {
                id
                processHandle
              }
              assignee {
                name
              }
            }
          }
        `,
        variables: {
          input: payload,
        },
      })
    },

    async updateTasks({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })

      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation updateTasks($input: [UpdateFutureTaskInput!]!) {
              updateTasks(updateTasksInput: $input) {
                nodes {
                  id
                  handle
                  init_handle
                  name
                  description
                  processId
                  userId
                  dueDate
                  status
                  is_late
                  deleted
                  updated_at
                  process {
                    id
                    processHandle
                  }
                  assignee {
                    name
                  }
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        commit('UPDATE_TASKS', response.data.updateTasks)
      } finally {
        commit('app/TOGGLE_LOADING', false, { root: true })
      }
    },
    async getPossibleValuesForFieldFromTaskEntity({ commit }, field) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`
            query getPossibleValuesForFieldFromTaskEntity($field: String!) {
              getPossibleValuesForFieldFromTaskEntity(field: $field)
            }
          `,
          variables: {
            field,
          },
        })
        if (response.data.getPossibleValuesForFieldFromTaskEntity) {
          commit(
            'SET_FIELD_VALUES',
            response.data.getPossibleValuesForFieldFromTaskEntity
          )
        }
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
