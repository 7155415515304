import gql from 'graphql-tag'
import { apolloClient } from '@/libs/vue-apollo'

export default {
  namespaced: true,
  state: {
    matrix: [],
    matrixTable: [],
    labelsPrefixes: [],
    savedMatrix: {},
    deletedMatrix: 0,
    isLoading: false,
  },

  // Mutations
  mutations: {
    MAKE_MATRIX(state, data) {
      const matrix = []
      for (let index = 0; index < data.length; index += 1) {
        let Row = {}
        const row = data[index]
        row.map(async item => {
          Row = { ...Row, [item.name]: item.value }
        })
        matrix.push(Row)
      }
      state.matrix = matrix
    },
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    LABELS_PREFIX(state, data) {
      state.labelsPrefixes = data
    },
    MATRIX_SAVED(state, data) {
      state.savedMatrix = data.name
    },
    MATRIX_TABLE(state, data) {
      state.matrixTable = data
    },
    MATRIX_DELETE(state) {
      state.deletedMatrix += 1
    },
    MATRIX_UPDATED(state, data) {
      state.savedMatrix = data.name
    },
  },

  // Actions
  actions: {
    async getMatrix({ commit }, data) {
      const { row, col } = data
      const response = await apolloClient.query({
        query: gql`
          query getMatrix($row: String!, $col: String!) {
            getMatrix(row: $row, col: $col) {
              name
              value
              id
            }
          }
        `,
        variables: {
          row,
          col,
        },
      })
      commit('MAKE_MATRIX', response.data.getMatrix)
    },

    async getLabelsPrefix({ commit }) {
      const rows = await apolloClient.query({
        query: gql`
          query getLabelsPrefix {
            getLabelsPrefix {
              label
              id
            }
          }
        `,
      })
      commit('LABELS_PREFIX', rows.data.getLabelsPrefix)
    },

    async createSavedMatrix({ commit }, data) {
      const { row, col, name, privacy } = data
      const result = await apolloClient.mutate({
        mutation: gql`
          mutation makeMatrix(
            $row: String!
            $col: String!
            $name: String!
            $privacy: Boolean!
          ) {
            makeMatrix(
              matrix: { row: $row, col: $col, privacy: $privacy, name: $name }
            ) {
              name
            }
          }
        `,
        variables: {
          row,
          col,
          name,
          privacy,
        },
      })
      commit('MATRIX_SAVED', result.data.makeMatrix)
    },

    async getSavedMatrixes({ commit }, data) {
      commit('SET_LOADING', true)
      const { page, perPage, q } = data
      try {
        const results = await apolloClient.query({
          query: gql`
            query getSavedMatrixes(
              $page: Float!
              $perPage: Float!
              $q: String!
            ) {
              getSavedMatrixes(page: $page, perPage: $perPage, q: $q) {
                nodes {
                  id
                  name
                  col
                  row
                  owner {
                    name
                  }
                  privacy
                }
                totalCount
              }
            }
          `,
          variables: {
            page,
            perPage,
            q,
          },
        })
        commit('MATRIX_TABLE', results.data.getSavedMatrixes)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async deleteMatrix({ commit }, id) {
      const result = await apolloClient.mutate({
        mutation: gql`
          mutation deleteSavedMatrix($id: Float!) {
            deleteSavedMatrix(id: $id)
          }
        `,
        variables: {
          id,
        },
      })
      commit('MATRIX_DELETE', result.data.deleteSavedMatrix)
    },

    async updateMatrix({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        const { row, col, id, privacy, name } = data
        const result = await apolloClient.mutate({
          mutation: gql`
            mutation updateSavedMatrix(
              $id: Float!
              $row: String!
              $col: String!
              $name: String!
              $privacy: Boolean!
            ) {
              updateSavedMatrix(
                updateMatrixInputDto: {
                  id: $id
                  row: $row
                  col: $col
                  privacy: $privacy
                  name: $name
                }
              ) {
                name
              }
            }
          `,
          variables: {
            id,
            row,
            col,
            privacy,
            name,
          },
        })
        commit('MATRIX_UPDATED', result.data.updateSavedMatrix)
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
