import _ from 'lodash'
import { getWeekdayOccurrence } from './date-utils'
import moment from 'moment'
import { isObjectNotEmpty } from './object-util'
import { capital } from 'case'

export const TASK_DUE_DATE_DISPLAY_FORMAT = 'ddd MMM D YYYY'

export const resolveTaskStatusVariant = (status) => {
  if (status === 'NotStarted') return 'secondary'
  if (status === 'Complete') return 'success'
  if (status === 'InProgress') return 'info'
  if (status === 'Exported') return 'warning'
  return 'primary'
}

export const removeExtraFieldsFromTask = (t) => {
  const task = {};
  ['id', 'handle', 'init_handle', 'name', 'description', 'processId', 'userId', 'assigneeId', 'dueDate', 'status', 'deleted', 'isManuallyCreated', 'created_at'].forEach(f => (task[f] = t[f]))

  if (typeof t.dueDate !== 'string') {
    task.dueDate = task.dueDate.format('YYYY-MM-DD')
  }

  return task
}

export const generateTaskHandle = (process, user, dueDate) => `${process.id}-${user.id}-${dueDate.format('YYYY-MM-DD')}`

export const formatProcessesForTaskGeneration = (processes) => {
  return _.cloneDeep(processes).map(process => {
    Object.keys(process.frequency).forEach((freqKey) => {
      if (!process.frequency[freqKey] || !process.frequency[freqKey].days) return;

      process.frequency[freqKey].daysHash = _.mapValues(_.keyBy(process.frequency[freqKey].days), () => true)

      Object.keys(process.frequency[freqKey]).forEach((monthKey) => {
        if (monthKey === 'days' || monthKey === 'daysHash') return;

        process.frequency[freqKey][monthKey] = _.mapValues(_.keyBy(process.frequency[freqKey][monthKey]), () => true)
      })
    })

    return process
  })
}

export const shouldProcessBeGenerated = (date, process) => {
  const processFrequency = process.frequency

  const dateWeekday = date.format('ddd').toLowerCase();
  const currentMonthFrequency = processFrequency[date.format('MMM')];
  const interval = processFrequency.Interval;

  if (currentMonthFrequency) {

    // Check if specific day is listed in the month's days hash
    if (currentMonthFrequency.daysHash && currentMonthFrequency.daysHash[date.date()]) return true;

    // Check if process should run on specific weekday or all weekdays ('*')
    const weekdayObject = currentMonthFrequency[dateWeekday];
    if (weekdayObject && (weekdayObject['*'] || weekdayObject[getWeekdayOccurrence(date)])) return true;
  }

  // Handle weekly interval
  if (interval?.StartOn) {
    const startOn = moment(interval.StartOn).startOf('day');
    const weeksDifference = date.diff(startOn, 'weeks') + 1;

    return (
      date.isSameOrAfter(startOn) &&
      interval.RepeatOn === dateWeekday &&
      weeksDifference % interval.RepeatEvery === 0
    );
  }

  return false;
};

export const isValidTaskFilter = (f) => !f.startsWith("assignee") || /^assignee(=|\!=).+$/.test(f)

const today = moment().startOf('day')

export const formatPersistedTask = t => {
  const dueDate = moment(t.dueDate).startOf('day')
  return { ...t, processHandle: t.process.processHandle, dueDate, is_late: dueDate.isBefore(today) && t.status !== 'Complete' }
}
export const generateFutureTasks = (processes, persistedTasks, currentPageTasks, startDate, pageSize, filters, isGoingBack, lastDynamicTaskEveryPage, tasksToSkip) => {
  const persistedTaskWithIdx = persistedTasks.map((p, idx) => ({ ...p, idx }));
  const persistedTasksHash = _.keyBy(persistedTaskWithIdx, t => t.init_handle.split('_')[0])
  const currentPageDynamicTasks = currentPageTasks.filter(f => f.isDynamic)
  const excludedUsers = {}
  const includedUsers = {}
  const textSearchQueries = []
  let dateRangeFilter = {}
  let hasNextPage = true
  const searchableColumns = [
    'processHandle',
    'name',
    'assigneeName',
    // 'statusFormatted',
    // 'dueDateFormatted'
  ];
  filters.forEach(f => {
    // [_, assignee filter type (!= | =), user name]
    const matches = f.match(/^assignee(!?=)(.+)$/)
    if (matches) {
      if (matches[1] === '=') {
        includedUsers[matches[2]] = true
      } else {
        excludedUsers[matches[2]] = true
      }
    } else if (f.startsWith('date=')) {
      const parts = f.split('=')[1]
      const [start, end] = parts.split(' to ')
      dateRangeFilter = {
        startDate: moment(start),
        endDate: moment(end)
      }
    } else if (!f.startsWith('is:') && !f.startsWith('isNot:') && f !== 'all:tasks') {
      textSearchQueries.push(f)
    }
  })

  const shouldExcludeUsers = isObjectNotEmpty(excludedUsers)
  const shouldIncludeUsers = isObjectNotEmpty(includedUsers)

  if (filters.some(appliedFilter => ({
    'is:complete': true,
    'is:late': true,
    'is:deleted': true,
  })[appliedFilter])) {
    return {
      tasks: persistedTasks.map(formatPersistedTask),
      dynamicTasks: [],
      tasksUsedFromDB: persistedTasks.length
    }
  }

  const shouldntSkip = filters.some(f => f === 'all:tasks') || !filters.length

  const lastTask = isGoingBack ? lastDynamicTaskEveryPage[lastDynamicTaskEveryPage.length - 2] : currentPageDynamicTasks[currentPageDynamicTasks.length - 1]

  let anchorDate = (lastTask ? lastTask.dueDate : (dateRangeFilter.startDate || startDate)).clone().startOf('day')
  const maxIterations = 365 * pageSize
  let dynamicTasks = [];
  let i = 0;

  while (i < maxIterations && dynamicTasks.length < pageSize) {
    if (dateRangeFilter.endDate && anchorDate.isAfter(dateRangeFilter.endDate)) {
      hasNextPage = false
      break
    };
    let j = 0;
    processes.forEach((process, idx) => {
      // Stop generating new tasks
      if (dynamicTasks.length === pageSize || i >= maxIterations) return;

      // Skip process if shouldn't be generated
      if (!shouldProcessBeGenerated(anchorDate, process)) return;



      process.users.forEach((user, idx2) => {
        j++
        if (dynamicTasks.length === pageSize || i >= maxIterations) return;
        if (!isGoingBack && lastTask && j <= lastTask.j && lastTask.dueDate.isSame(anchorDate)) return
        if (isGoingBack && lastTask && j > lastTask.j && lastTask.dueDate.isSame(anchorDate)) return
        if (shouldIncludeUsers && !includedUsers[user.name]) return;
        if (shouldExcludeUsers && excludedUsers[user.name]) return

        const handle = generateTaskHandle(process, user, anchorDate)
        if (tasksToSkip[handle] && !shouldntSkip) return;

        const persistedTask = persistedTasksHash[handle]

        const task = {
          processId: process.id,
          processHandle: process.processHandle,
          name: process.name,
          dueDateFormatted: anchorDate.format(TASK_DUE_DATE_DISPLAY_FORMAT),
          userId: user.id,
          assignee: user,
          assigneeName: user.name,
          status: "NotStarted",
          statusFormatted: "Not started",
          init_handle: handle,
          handle,
          isDynamic: true,

          ...(persistedTask),

          j,
          dueDate: anchorDate.clone(),
        }


        if (persistedTask) {
          delete persistedTasksHash[handle]
        }

        if (textSearchQueries.length && !textSearchQueries.some(query =>
          searchableColumns.some(column =>
            task[column]?.toString().toLowerCase().includes(query.toLowerCase())
          )
        )) return

        dynamicTasks.push(task)
      })
    })
    if (isGoingBack) {
      anchorDate.subtract(1, 'day')
    } else {
      anchorDate.add(1, 'day')
    }
    i++
  }

  const formattedDBTasks = Object.values(persistedTasksHash).map(formatPersistedTask)
  const tasks = dynamicTasks.concat(formattedDBTasks).sort((a, b) => a.dueDate.isBefore(b.dueDate) ? -1 : 1).slice(0, pageSize)
  return { tasks, dynamicTasks, tasksUsedFromDB: tasks.filter(t => !!t.id).length, hasNextPage: hasNextPage && tasks.length === pageSize }
};
