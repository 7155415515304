import moment from 'moment'

/**
 * handles timezone and formats date to YYYY-MM-DD
 * @param d
 */
// eslint-disable-next-line import/prefer-default-export
export function formatDate(d) {
  // const offset = d.getTimezoneOffset()
  // const date = new Date(d.getTime() - (offset * 60 * 1000))
  return d.toISOString().split('T')[0]
}

export function formatDateForUser(d) {
  // const offset = d.getTimezoneOffset()
  // const date = new Date(d.getTime() - (offset * 60 * 1000))
  return moment(d).format('YYYY-MM-DD')
}

export function getWeekdayOccurrence(date) {
  // Parse the date and get the month and year
  const targetDate = moment(date);
  const month = targetDate.month();
  const year = targetDate.year();
  const weekday = targetDate.day(); // Sunday is 0, Monday is 1, etc.

  // Find the first date of the month
  const firstDayOfMonth = moment([year, month, 1]);

  // Count the occurrences of the target weekday in the month
  let occurrence = 0;
  for (let i = 1; i <= 31; i++) {
    const currentDay = moment([year, month, i]);
    if (currentDay.month() === month && currentDay.day() === weekday) {
      occurrence++;
    }
    if (currentDay.month() !== month) break; // Stop if we reach the next month
  }

  return occurrence; // This will give you 1, 2, 3, 4, or 5
}