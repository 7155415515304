import firebase from 'firebase'
// import firebase from 'firebase/app'
// import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyA5TzPM_mzuwSsmXUhtPXC5V0OrlITmqMc',
  authDomain: 'brave-scanner-230504.firebaseapp.com',
  databaseURL: 'https://brave-scanner-230504.firebaseio.com',
  projectId: 'brave-scanner-230504',
  storageBucket: 'brave-scanner-230504.appspot.com',
  messagingSenderId: '1026266720759',
  appId: '1:1026266720759:web:ddcf552bdc2c0766a3a6f2',
}

firebase.initializeApp(config)

export default firebase
